import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import { Divider } from "@material-ui/core";
// import icons from "../../../../assets/icons/icon-collection.svg";
import Person from "../../../../assets/icons/person.svg";
import Percent from "../../../../assets/icons/percent.svg";
import Bars from "../../../../assets/icons/bars.svg";
import Clock from "../../../../assets/icons/clock.svg";
import Money from "../../../../assets/icons/money.svg";
// import { Chart } from "react-google-charts";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './index.css';
import {
  getAvgTimeToHire,
  getQualityOfHire,
  getEmployeeEngagement,
  getCostOfHire,
  getEmployeeReferralRatio,
  getCostOfHireAnalytics,
  getEmploymentEngagementAnalytics,
  getReferalRationalytics,
  getPieChartAnalytics,
  getAverageTimeToHireAnalytics
} from "../../../../redux/actions/businessActions";
 
import { getReferralStats } from "../../../../redux/actions/insightActions";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import backArrowLogo from './back-arrow.svg';
 

const Insights = () => {

  const [panelstateAverageTime, setPanelstateAverageTime] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [panelstateQualityOfHire, setPanelstateQualityOfHire] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [panelstateCostOfHire, setPanelstateCostOfHire] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  
  const [panelstateEmployeeEngagement, setPanelstateEmployeeEngagement] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
   
  const [panelstateEmployeeReferralRatio, setPanelstateEmployeeReferralRatio] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
   
   

   

  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.auth);
  const {
    avg_tth,
    qualityOfHirePercent,
    emp_engt,
    costOfHire,
    costOfHireAnalyticsData,
    employeeEngagementAnalyticsData,
    employeeReferalRatioAnalyticsData,
    pieChartAnalyticsData,
    averageTimeToHireAnalyticsData,
    employeeReferralRatio,
  } = useSelector((state) => state.business);

  const { referralStats } = useSelector(({ insight }) => insight);

  const company_id = userData?.company?.id;
  const qOfHire = qualityOfHirePercent ? qualityOfHirePercent.qualityOfHire : 0;

  useEffect(() => {
    Promise.resolve([
      getAvgTimeToHire(dispatch, company_id),
      getQualityOfHire(dispatch, company_id),
      getEmployeeEngagement(dispatch, company_id),
      getCostOfHireAnalytics(dispatch,company_id),
      getCostOfHire(dispatch, company_id),
      getEmployeeReferralRatio(dispatch, company_id),
      getEmploymentEngagementAnalytics(dispatch, company_id),
      getReferalRationalytics(dispatch,company_id),
      getPieChartAnalytics(dispatch,company_id),
      getReferralStats(dispatch),
      getAverageTimeToHireAnalytics(dispatch,company_id)
    ]).finally(() => {
      
    });
  }, [1]);
  return (
    <>
      {/* <Card additionalClass="d-flex justify-content-end">
        <span className="co-primary d-flex align-items-center">
          Filter
          <svg width="18" height="15" className="ml-2">
            <use href={`${icons}#filter`}></use>
          </svg>
        </span>
      </Card> */}
      <Card additionalClass="mt-4 p-4">
       <h5>Referral Insights</h5>
        <div className="d-flex border-primary">
          <span className="w-50 py-2 text-center co-white bg-primary">
            <b>Total Referrals</b>
          </span>
          <span className="w-50 py-2 text-center co-primary">
            <b>{referralStats?.clicksByReferral}</b>
          </span>
        </div>
        <div className="mt-4 position-relative d-flex justify-content-center">
          {<img src={Chart} alt="chart" /> 
          }
           
          {/*START PIE CHART
          [
            ['Task', 'Hours per Day'],
            ['Applied', 11],
            ['Not Applied', 2],
            ['Interviewed', 2],
            ['Hired', 2],
          ]
          */}
          {/* <Chart
          width={'600px'}
          height={'400px'}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={pieChartAnalyticsData}
          options={{
            title: 'Referal Insights',
            // Just add this option
            is3D: true,
          }} */}
         
          
          {/* rootProps={{ 'data-testid': '1' }}
        /> */}
          {/* END PIE CHART */}

          <div className="figure position-absolute">
            <span className="d-flex flex-column align-items-center justify-content-center">
              <b>{referralStats?.clicksByReferral}</b>
             <span className="co-darkgray smalltext">Total Viewed</span>
            </span>
          </div>
        </div>
       <div className="d-flex justify-content-center">
          <span className="d-flex flex-column align-items-center p-3">
            <span
              style={{
                background: "#304FFD",
                width: "0.5rem",
                padding: "0.25rem",
                borderRadius: "0.1rem",
              }}
            ></span>
            <b className="mt-1">{referralStats?.appliedForJob}</b>
            <span className="co-darkgray smalltext">Applied</span>
          </span>
          <Divider orientation="vertical" flexItem />
          <span className="d-flex flex-column align-items-center p-3">
            <span
              style={{
                background: "#FFD240",
                width: "0.5rem",
                padding: "0.25rem",
                borderRadius: "0.1rem",
              }}
              className="px-1"
            ></span>
            <b className="mt-1">
              {referralStats?.clicksByReferral - referralStats?.appliedForJob}
            </b>
            <span className="co-darkgray smalltext">Did-Not-Apply</span>
          </span>
        
        </div>
      </Card>
              <div className="mt-3"></div>
     
     {/* <Tabs>
    <TabList>
      <Tab>Overview</Tab>
      <Tab>Referrals Demographics</Tab>
      <Tab>Historgram</Tab>
      <Tab>Bar Chart</Tab>
      <Tab>Revenue</Tab>

    </TabList>

    <TabPanel>
   
    </TabPanel>
   
   
    <TabPanel>
        <Chart
      width={'100%'}
      height={'300px'}
      chartType="GeoChart"
      data={[
        ['Country', 'Popularity'],
        ['Germany', 200],
        ['United States', 300],
        ['Brazil', 400],
        ['Canada', 500],
        ['France', 600],
        ['RU', 700],
      ]}
      // Note: you will need to get a mapsApiKey for your project.
      // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
      mapsApiKey="YOUR_KEY_HERE"
      rootProps={{ 'data-testid': '1' }}
    />
    </TabPanel>

    <TabPanel>
          <Chart
        width={'100%'}
        height={'300px'}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            'Month',
            'Bolivia',
            'Ecuador',
            'Madagascar',
            'Papua New Guinea',
            'Rwanda',
            'Average',
          ],
          ['2004/05', 165, 938, 522, 998, 450, 614.6],
          ['2005/06', 135, 1120, 599, 1268, 288, 682],
          ['2006/07', 157, 1167, 587, 807, 397, 623],
          ['2007/08', 139, 1110, 615, 968, 215, 609.4],
          ['2008/09', 136, 691, 629, 1026, 366, 569.6],
        ]}
        options={{
          title: 'Monthly Referals   X',
          vAxis: { title: 'Cups' },
          hAxis: { title: 'Month' },
          seriesType: 'bars',
          series: { 5: { type: 'line' } },
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </TabPanel>


    <TabPanel>
    <Chart
      width={'100%'}
      height={'300px'}
      chartType="BarChart"
      loader={<div>Loading Chart</div>}
      data={[
        ['City', '2010 Population', '2000 Population'],
        ['New York City, NY', 8175000, 8008000],
        ['Los Angeles, CA', 3792000, 3694000],
        ['Chicago, IL', 2695000, 2896000],
        ['Houston, TX', 2099000, 1953000],
        ['Philadelphia, PA', 1526000, 1517000],
      ]}
      options={{
        title: 'Population of Largest U.S. Cities',
        chartArea: { width: '50%' },
        hAxis: {
          title: 'Total Population',
          minValue: 0,
        },
        vAxis: {
          title: 'City',
        },
      }}
      // For tests
      rootProps={{ 'data-testid': '1' }}
    />

 
    </TabPanel>

    <TabPanel>

    <Chart
      width={'100%'}
      height={'300px'}
      chartType="Table"
      loader={<div>Loading Chart</div>}
      data={[
        ['Department', 'Revenues ($)'],
        ['I.T', 10700],
        ['Accounting', 12500],
        ['Socials', -2100],
        ['H.r', 22600],
       ]}
      formatters={[
        {
          type: 'BarFormat',
          column: 1,
          options: {
            width: 120,
          },
        },
      ]}
      options={{
        allowHtml: true,
        showRowNumber: true,
        width: '100%',
        height: '100%',
      }}
      rootProps={{ 'data-testid': '2' }}
    />

    </TabPanel>


  </Tabs> */}


  <div className="row px-1">
        <div className="col-6">
          <Card additionalClass="mx-1 mt-3 d-flex cursor-pointer" 
           handleClick={() =>
            {              
             setPanelstateAverageTime({ isPaneOpenLeft: true ,isPaneOpen:true})}
            }
          >
            <img src={Clock} alt="person" />
            <span className="d-flex flex-column ml-3">
              <span className="co-gray">Average Time to Hire</span>
              <b className="large-text">{avg_tth ? avg_tth.avg_tth : 0} Days</b>
            </span>
           
          </Card>
        </div>
        <div className="col-6">
          <Card additionalClass="mx-1 mt-3 d-flex cursor-pointer"
           handleClick={() =>
            {              
             setPanelstateQualityOfHire({ isPaneOpenLeft: true ,isPaneOpen:true})}
            }
          >
            <img src={Person} alt="person" />
            <span className="d-flex flex-column ml-3">
              <span className="co-gray">Quality of Hire</span>
              <b className="large-text">{qOfHire}%</b>
            </span>
          </Card>
        </div>
        <div className="col-6">
          <Card additionalClass="mx-1 mt-3 d-flex cursor-pointer"
           handleClick={() =>
            {              
             setPanelstateCostOfHire({ isPaneOpenLeft: true ,isPaneOpen:true})}
            }
          >
            <img src={Money} alt="person" />
            <span className="d-flex flex-column ml-3">
              <span className="co-gray">Cost of Hire</span>
              <span className="d-flex align-items-center">
                <b className="large-text">{costOfHire?.costOfHire || 0}</b>
                {/* <span className="co-green d-flex flex-column ml-3">
                  <svg width="14" height="14" className="rounded-circle">
                    <use href={`${icons}#up`}></use>
                  </svg>
                  50%
                </span> */}
              </span>
            </span>
          </Card>
        </div>
        <div className="col-6">
          <Card additionalClass="mx-1 mt-3 d-flex cursor-pointer"
          
          handleClick={() =>
            {              
              setPanelstateEmployeeEngagement({ isPaneOpenLeft: true ,isPaneOpen:true})}
            }
          >
            <img src={Bars} alt="person" />
            <span className="d-flex flex-column ml-3">
              <span className="co-gray">Employee Engagement</span>
              <span className="d-flex align-items-center">
                <b className="large-text">
                  {emp_engt?.employeeEngagement || 0}
                </b>
                {/* <span className="co-red d-flex flex-column ml-3">
                  <svg width="14" height="14" className="rounded-circle">
                    <use href={`${icons}#down`}></use>
                  </svg>
                  50%
                </span> */}
              </span>
            </span>
          </Card>
        </div>
        <div className="col-6">
          <Card additionalClass="mx-1 mt-3 d-flex cursor-pointer"
            handleClick={() =>
              {              
                setPanelstateEmployeeReferralRatio({ isPaneOpenLeft: true ,isPaneOpen:true})}
              }
          >
            <img src={Percent} alt="person" />
            <span className="d-flex flex-column ml-3">
              <span className="co-gray">Employee Referral Ratio</span>
              <span className="d-flex align-items-center">
                <b className="large-text">
                  {" "}
                  {employeeReferralRatio?.engagedEmployess
                    ? `${employeeReferralRatio.engagedEmployess}:${employeeReferralRatio.employeeCount}`
                    : 0}
                </b>
                {/* <span className="co-green d-flex flex-column ml-3">
                  <svg width="14" height="14" className="rounded-circle">
                    <use href={`${icons}#up`}></use>
                  </svg>
                  50%
                </span> */}
              </span>
            </span>
          </Card>
        </div>
      </div>
  

  
{/* Average time to Hire */}
      <SlidingPane
        className="ChartAverageTimetoHire"
        overlayClassName="some-custom-overlay-class"
        isOpen={panelstateAverageTime.isPaneOpen}
        title=""
        subtitle=""
    
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPanelstateAverageTime({ isPaneOpen: false });
        }}
      >
        <a href="#"
        onClick={()=>{
          setPanelstateAverageTime({ isPaneOpen: false });
        }}
        >
        <img src={backArrowLogo} className="back-arrow" />
        </a>
         <h2>Average Time to Hire</h2>
        <select>
          <option>Select Department</option>
          <option></option>
        </select>
        <select>
          <option>Select Level</option>
          <option></option>
        </select>

        <input type="date" />
        <div>
{/* 
[
        ['Months', 'Employees Hired'],
        ['Jan', 100],
        ['Feb', 55],
        ['Mar', 14],
        ['Apr', 5],
        ['May', 35],
        ['Jun', 90],
        ['Jul', 17],
        ['Aug', 47],
        ['Sept', 67],
        ['Oct', 86],
        ['Nov', 99],
        ['Dec', 65],
      ]
*/}

         
        <Chart
      width={'100%'}
      height={'500px'}
      chartType="ScatterChart"
      loader={<div>Loading Chart</div>}
      data={averageTimeToHireAnalyticsData}
      options={{
        title: '',
        hAxis: { title: 'Months', minValue: 0, maxValue: 15 },
        vAxis: { title: 'Employees Hired', minValue: 0, maxValue: 15 },
        legend: 'none',
      }}
      
      rootProps={{ 'data-testid': '1' }}
 


    />
        </div>
        
      </SlidingPane>

  
{/* Quality of Hire */}
      <SlidingPane
        className=""
        overlayClassName="some-custom-overlay-class"
        isOpen={panelstateQualityOfHire.isPaneOpen}
        title=""
        subtitle=""
        
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPanelstateQualityOfHire({ isPaneOpen: false });
        }}
      >
        <a href="#"
        onClick={()=>{
          setPanelstateQualityOfHire({ isPaneOpen: false });
        }}
        >
        <img src={backArrowLogo} className="back-arrow" />
        </a>
         <h2>Quality of Hire</h2>
        
        <div>

          

        
        </div>
        
      </SlidingPane>


{/* Cost of Hire */}
<SlidingPane
        className=""
        overlayClassName="some-custom-overlay-class"
        isOpen={panelstateCostOfHire.isPaneOpen}
        title=""
        subtitle=""
    
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPanelstateCostOfHire({ isPaneOpen: false });
        }}
      >
        <a href="#"
        onClick={()=>{
          setPanelstateCostOfHire({ isPaneOpen: false });
        }}
        >
        <img src={backArrowLogo} className="back-arrow" />
        </a>
         <h2>Cost of Hire</h2>

         
       Filter from <input type="date" />
      
       to <input type="date" />

        <div>
          
        <Chart
        width={'100%'}
        height={'500px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={costOfHireAnalyticsData}
        options={{
          hAxis: {
            title: 'Month',
          },
          vAxis: {
            title: 'Amount($)',
          },
        }}
        rootProps={{ 'data-testid': '1' }}
      />

        
        </div>
        
      </SlidingPane>



{/* Employee Engagement */}
<SlidingPane
        className="chartbg"
        overlayClassName="some-custom-overlay-class"
        isOpen={panelstateEmployeeEngagement.isPaneOpen}
        title=""
        subtitle=""
    
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPanelstateEmployeeEngagement({ isPaneOpen: false });
        }}
      >
        <a href="#"
        onClick={()=>{
          setPanelstateEmployeeEngagement({ isPaneOpen: false });
        }}
        >
        <img src={backArrowLogo} className="back-arrow" />
        </a>
         <h2>Employee Engagement</h2>
  
       Filter from <input type="date" />
      
       to <input type="date" />

        <div>
          
        <Chart
  width={'100%'}
  height={'500px'}
  chartType="BarChart"
  loader={<div>Loading Chart</div>}
  data={employeeEngagementAnalyticsData}
  options={{
    title: '',
    chartArea: { width: '50%' },
    isStacked: true,
    hAxis: {
      title: 'Percentage of Response',
      minValue: 0
    },
    vAxis: {
      title: 'Month',
    },
  }}
  // For tests
  rootProps={{ 'data-testid': '3' }}
/>

        
        </div>
        
      </SlidingPane>


{/* Employee Referral Ratio*/}
<SlidingPane
        className="chartbg"
        overlayClassName="some-custom-overlay-class"
        isOpen={panelstateEmployeeReferralRatio.isPaneOpen}
        title=""
        subtitle=""
    
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPanelstateEmployeeReferralRatio({ isPaneOpen: false });
        }}
      >
        <a href="#"
        onClick={()=>{
          setPanelstateEmployeeReferralRatio({ isPaneOpen: false });
        }}
        >
        <img src={backArrowLogo} className="back-arrow" />
        </a>
         <h2>Employee Referral Ratio</h2>
       
         {/*
          [
          [
            'Month',
            'Employee',
            'Referrer',
          ],
          ['2004/05', 165, 938],
          ['2005/06', 135, 1120],
          ['2006/07', 157, 1167],
          ['2007/08', 139, 1110],
          ['2008/09', 136, 691],
        ]
        */}

        <div>
        <Chart
        width={'100%'}
        height={'500px'}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={employeeReferalRatioAnalyticsData}
        options={{
          title: '',
          vAxis: { title: 'Ratio' },
          hAxis: { title: 'Month' },
          seriesType: 'bars',
          series: { 5: { type: 'line' } },
        }}
        rootProps={{ 'data-testid': '1' }}
      />

        
        </div>
        
      </SlidingPane>


  
  
    </>
  );
};

export default Insights;
