import React, { useEffect, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Visits from "../../../../assets/icons/visits.svg";
import Views from "../../../../assets/icons/views.svg";
import Referral from "../../../../assets/icons/referral.svg";
import { Link } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import Card from "../../../../components/Card";
import icons from "../../../../assets/icons/icon-collection.svg";
import SearchInput from "../../../../components/CustomSearchInput";
import CustomTextArea from "../../../../components/CustomTextArea";
import ViewDetails from "../../../General/Home/Modal/ViewDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationStats,
  getMyApplicationInsight,
  withdrawApplication,
} from "../../../../redux/actions/insightActions";
const applicationStyleMapper = {
  0: "sent",
  1: "considered",
  2: "test",
  3: "test",
  4: "hired",
  5: "declined",
};

const applicationStatusMapper = {
  0: "Application Sent",
  1: "You're being considered",
  2: "Test Stage",
  3: "Interviewing",
  4: "Hired",
  5: "Application Declined",
};

const Applications = () => {
  const history = useHistory();
const [anchorEl, setAnchorEl] = useState({ element: null, id: null });
  const [search, setSearch] = useState("");
  const [jobContent, setJobContent] = useState({});
  const [openJobDetail, setOpenJobDetail] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [currentApplication, setCurrentApplication] = useState("");
  const [withdrawMessage, setWithdrawMessage] = useState("");
  const [section, setSection] = useState("details");

  const dispatch = useDispatch();
  const { applications, applicationStats } = useSelector(
    ({ insight }) => insight
  );
const handleClick = (event, id, posterId) => {
  console.log("Clicked item ID:", id, posterId); 
  setAnchorEl({ element: event.currentTarget, id, posterId });
};

const handleClose = () => {
  setAnchorEl({ element: null, id: null });
};
const openWithdrawDialog = () => {
  const id = anchorEl?.id; 
  if (id) {
    console.log("Opening withdraw dialog for ID:", id);
    setCurrentApplication(id);
    setWithdraw(true);
  } else {
    console.error("No application ID found in anchorEl");
  }
  };
  const handleApplicant = () => {
  const posterId = anchorEl?.posterId; 
    if (posterId) {
    console.log(posterId)
    history.push(`/profile/${posterId}`);
  } else {
    console.error("No application ID found in anchorEl");
  }
};
  const handleViewJobDetils = (application) => {
    setJobContent(application);
    setSection("details");
    setOpenJobDetail(true);
  };

  const viewJobDetails = () => {
    // history.push(`/home/${jobId}`);
    setSection("details");
    // setSubSection("details");
  };

  const viewDocument = () => {
    // history.push(`/home/${jobId}`);
    setSection("documents");
    // setSubSection("documents");
  };

  const closeJobDetail = () => {
    setOpenJobDetail(false);
  };

  

  const handleWithdrawMessageChange = (e) => {
    setWithdrawMessage(e.target.value);
  };

const handleWithdrawSubmit = async () => {
  if (!currentApplication) return;
  try {
    await withdrawApplication(dispatch, parseInt(currentApplication, 10), {
      message: withdrawMessage,
    });
    setWithdraw(false);
    setCurrentApplication(null);
    window.location.reload()
  } catch (error) {
    console.error("Withdraw action failed", error);
  }
};



  useEffect(() => {
    Promise.all([
      getMyApplicationInsight(dispatch),
      getApplicationStats(dispatch),
    ]);
  }, [dispatch]);

  return (
    <div className="w-100">
      <div className="d-flex justify-content-md-between justify-content-around mx-5 my-3">
        <div className="count co-primary">
          <div className="figure">
            <img src={Visits} alt="profile visits" />
            <b>{applicationStats?.profileVisit || 0}</b>
          </div>
          <p className="my-1 text-center">
            Profile <br /> Visits
          </p>
        </div>
        <div className="count co-primary">
          <div className="figure">
            <img src={Referral} alt="Successful Referrals" />
            <b>{applicationStats?.successfulReferral || 0}</b>
          </div>
          <p className="my-1 text-center">
            Successful <br /> Referral
          </p>
        </div>
        <div className="count co-primary">
          <div className="figure">
            <b>{applicationStats?.cvViews || 0}</b>
            <img src={Views} alt="Resume Views" />
          </div>
          <p className="my-1 text-center">
            Resume <br /> Views
          </p>
        </div>
      </div>
      <Divider />
      <div className="row m-4">
        <SearchInput
          handleChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        

        {applications && (
          <>
            {applications
              .filter((application) => {
                if (
                  search &&
                  !JSON.stringify(application)
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return false;
                }
                return true;
              })
              .map((application) => {
                return (
                  <div key={application.id} className="w-100 my-4">
                  <Card noPadding additionalClass="p-0 mb-4">
                    <div
                      className={`d-flex align-items-center status-${
                        applicationStyleMapper[application.applicationStage]
                      } px-3 py-2`}
                    >
                      <span className="mr-2">
                        <svg width="13" height="13">
                          <use href={`${icons}#elipse`}></use>
                        </svg>
                      </span>
                      {applicationStatusMapper[application.applicationStage]}
                    </div>
                    <div className="mx-4 py-3">
                      <div className="d-flex justify-content-between pb-1">
                        <div className="d-flex flex-column my-1">
                          <div className="mb-3">
                            {" "}
                            <b className="d-block co-primary">
                              {application.role_title}
                            </b>
                            <span className="co-gray medium-text">
                              {application.campany_name}
                            </span>
                          </div>
                        </div>

                        <IconButton onClick={(event) => handleClick(event, application.id, application.created_by)}>
  <svg width="20" height="15">
    <use href={`${icons}#dots`}></use>
  </svg>
</IconButton>

                      </div>
                      <span>
                        {
                          <span
                            dangerouslySetInnerHTML={{
  __html: application.about_company
    ? application.about_company.substring(0, 216)
    : "No details available.",
}}
                          ></span>
                        }

                        <button
                          type="button"
                          onClick={() => handleViewJobDetils(application)}
                          className="co-primary bg-white borderless"
                        >
                          <b>See More</b>
                        </button>
                      </span>

                      <Menu
                        id="profile-menu"
                         anchorEl={anchorEl?.element || null}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom" }}
                        keepMounted
                       open={Boolean(anchorEl?.element)}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: "15rem",
                            marginTop: "0.2rem",
                            borderRadius: "1rem",
                            boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
                          },
                        }}
                      >
                        <MenuItem
                          className="px-4 py-2 medium-text"
                          onClick={()=>handleApplicant()}
                        >
                          View Poster's Profile
                        </MenuItem>
                        <MenuItem
                          className="px-4 py-2 medium-text"
                          // onClick={openDialog}
                        >
                          <Link to="/profile"> Edit Your Profile</Link>
                        </MenuItem>
                        <MenuItem
                          className="px-4 py-2 medium-text"
                          onClick={() => openWithdrawDialog()}
                        >
                          Withdraw Application
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          className="px-4 py-3 medium-text"
                          onClick={handleClose}
                        >
                          Cancel
                        </MenuItem>
                      </Menu>
                    </div>
                  </Card>
                </div>
                )
              })}
          </>
        )}
      </div>

      {withdraw && (
        <Dialog
          open={withdraw}
          onClose={() => setWithdraw(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Withdraw Application</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Let us know why you want to withdraw application
            </DialogContentText>
            <CustomTextArea
              id="report_reason"
              label=""
              value={withdrawMessage}
              onChange={(e) => handleWithdrawMessageChange(e)}
              isRequired
            />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="primary">
              Cancel
            </Button> */}
            <Button onClick={handleWithdrawSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={openJobDetail}
        onClose={closeJobDetail}
        aria-labelledby="View details"
        PaperProps={{
          style: {
            minWidth: "50rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        {/* new job card header */}
        <div className="d-flex justify-content-between align-items-centter">
          <DialogTitle id="details-dialog-title">
            <div>
              <h2 className="mb-0">{jobContent.role_title}</h2>
              <p className="mb-0 co-darkgray">{jobContent.campany_name}</p>
            </div>
          </DialogTitle>
          <div className="close-button">
            <IconButton onClick={closeJobDetail}>
              <svg width="30" height="30" className="rounded-circle">
                <use href={`${icons}#close`}></use>
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="position-relative sticky">
          <div className="bg-white w-100 position-absolute">
            <div className="d-flex px-4 justify-content-start">
              <button
                onClick={viewJobDetails}
                className="co-white mr-2 px-4 py-2 fw-bold border-0 bg-primary rounded"
              >
                Job Description
              </button>
              <button
                onClick={viewDocument}
                className="co-white px-4 py-2 fw-bold border-0 bg-darkgrey rounded"
              >
                Additional Documents
              </button>
            </div>
            <div className="d-flex justify-content-start my-3 mx-4">
              <span className="mr-2">
                <svg width="17" height="16" className="mr-1">
                  <use href={`${icons}#briefcase`}></use>
                </svg>
                {jobContent.job_level && jobContent.job_level.title}
              </span>
              <span className="mr-2">
                <svg width="14" height="14" className="mr-1">
                  <use href={`${icons}#mappin`}></use>
                </svg>
                {jobContent.location}
              </span>
              <span className="mr-2">
                <svg width="24" height="17" className="mr-1">
                  <use href={`${icons}#persons`}></use>
                </svg>
                {jobContent.no_of_staff} workers
              </span>
            </div>
            <Divider />
          </div>
        </div>
        {/* new job card header */}

        <DialogContent>
          <ViewDetails job={jobContent} section={section} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Applications;
